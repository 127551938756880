import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'
import { CategoryKindType } from '../types/CategoryKind'

export type IHelpCategory = {
  /** ヘルプカテゴリID */
  helpCategoryId: string
  /** カテゴリ種別 */
  categoryKind: CategoryKindType
  /** タイトル */
  title: string
  /** スラッグ */
  slug: string
}

export type IHelpCategoryMap = {
  [helpCategoryId: string]: IHelpCategory
}

export type IHelpDetail = {
  /** ヘルプID */
  helpId: string
  /** ソート番号 */
  sortNo: number
  /** カテゴリID */
  helpCategoryId: string
  /** タイトル */
  title: string
  /** スラッグ */
  slug: string
}

type IReturn = {
  helpCategoryMap: IHelpCategoryMap
  helpDetails: IHelpDetail[]
}
/**
 * ヘルプ情報共通フック
 */
const useHelpData = (): IReturn => {
  const { awsGraphQL } = useStaticQuery<GatsbyTypes.useHelpDataQuery>(
    graphql`
      query useHelpData {
        awsGraphQL {
          listHelpDetails {
            items {
              helpId
              title
              sortNo
              helpCategoryId
              _deleted
            }
          }
          listHelpCategories {
            items {
              helpCategoryId
              categoryKind
              title
              slug
              _deleted
            }
          }
        }
      }
    `
  )

  const detailItems = awsGraphQL.listHelpDetails?.items || []
  const categoryItems = awsGraphQL.listHelpCategories?.items || []

  // ヘルプカテゴリIDがキーのマップ情報を作成
  const helpCategoryMap = useMemo(
    () =>
      categoryItems.reduce<IHelpCategoryMap>(
        // eslint-disable-next-line no-shadow
        (result, data) => {
          if (data?._deleted) return result
          return {
            ...result,
            [data?.helpCategoryId || '']: {
              helpCategoryId: data?.helpCategoryId || '',
              categoryKind: data?.categoryKind as CategoryKindType,
              title: data?.title || '',
              slug: data?.slug || '',
            },
          }
        },
        {}
      ),
    [categoryItems]
  )

  // ヘルプIDがキーの詳細マップ情報を作成
  const helpDetails = useMemo(() => {
    const _result: IHelpDetail[] = []
    detailItems.forEach((helpDetail) => {
      if (helpDetail?._deleted) return
      const _helpCategoryIds = (helpDetail?.helpCategoryId || '').split(',')
      const _helpId = helpDetail?.helpId || ''
      _helpCategoryIds.forEach((_helpCategoryId) => {
        if (helpCategoryMap[_helpCategoryId]) {
          const category = helpCategoryMap[_helpCategoryId]
          _result.push({
            helpId: _helpId,
            title: helpDetail?.title || '',
            sortNo: helpDetail?.sortNo || 999999,
            helpCategoryId: _helpCategoryId,
            slug: category.slug,
          })
        }
      })
    })
    _result.sort((a, b) => a.sortNo - b.sortNo)
    return _result
  }, [helpCategoryMap, detailItems])

  return { helpCategoryMap, helpDetails }
}

export default useHelpData
