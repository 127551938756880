import styled from '@emotion/styled'
import React from 'react'

const H3 = styled.h3`
  font-size: 1em;
  margin: 0 0 15px;
  border: none;
  display: inline-block;
  span {
    margin: 0 0 3px;
    display: inline-block;
    position: relative;
    border-bottom: solid 2px #e5e5e5;
    &:before {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 16px;
      height: 2px;
      content: '';
      background: #3ec7b3;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label: string
}

/**
 * 共通H3タグ
 * @param props
 * @constructor
 */
const CommonH3 = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <H3 className={className}>
      <span>{props.label}</span>
    </H3>
  )
}

export default CommonH3
