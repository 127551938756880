import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import { ITopicPath } from '../components/atoms/topicPath'
import ArticleSection from '../components/molecules/articleSection'
import Layout from '../components/organisms/layout'
import useHelpData, { IHelpDetail } from '../hooks/useHelpData'
import useRouteParam from '../hooks/useRouteParam'

type IProps = PageProps<GatsbyTypes.ArticlesPageQuery>
/** 関連記事で上下何件表示するか */
const SHOW_RELATION_COUNT = 999
/**
 * ヘルプ詳細ページ
 * @constructor
 */
const ArticlesPage = ({ data, location }: IProps) => {
  const { helpDetails, helpCategoryMap } = useHelpData()
  const helpId = data.awsGraphQL.getHelpDetail?.helpId || ''
  const title = data.awsGraphQL.getHelpDetail?.title || ''
  const helpCategoryIds = (
    data.awsGraphQL.getHelpDetail?.helpCategoryId || ''
  ).split(',')
  const description = data.awsGraphQL.getHelpDetail?.description || ''
  const body = data.awsGraphQL.getHelpDetail?.body || ''
  const relatedHelpId = data.awsGraphQL.getHelpDetail?.relatedHelpId || ''

  const helpCategoryId = helpCategoryIds[0] || 0
  const category = helpCategoryMap[helpCategoryId]

  // パンくず情報
  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    {
      title: category?.title || '',
      link: useRouteParam(`/help/${category?.slug}`),
    },
    { title },
  ]

  // 同一カテゴリの関連記事の取得
  let categoryHelps: IHelpDetail[] = helpDetails.filter(
    (val) => val.helpCategoryId === helpCategoryId
  )
  const index = categoryHelps.findIndex((val) => val.helpId === helpId)
  const beforeIndex = index - SHOW_RELATION_COUNT
  const afterIndex = index + SHOW_RELATION_COUNT
  categoryHelps = categoryHelps.filter(
    (val, _index) => beforeIndex <= _index && _index <= afterIndex
  )
  // ヘルプID指定の関連記事を取得
  const relatedHelps: IHelpDetail[] = []
  if (relatedHelpId) {
    relatedHelpId.split(',').forEach((_helpId) => {
      const helpDetail = helpDetails.find((val) => val.helpId === _helpId)
      if (helpDetail) relatedHelps.push({ ...helpDetail })
    })
  }

  return (
    <Layout
      location={location}
      paths={paths}
      categoryKind={category?.categoryKind}
    >
      <SEO title={title} description={description} />
      <ArticleSection
        helpId={helpId}
        title={title}
        body={body}
        categoryHelps={categoryHelps}
        relatedHelps={relatedHelps}
      />
    </Layout>
  )
}

export default ArticlesPage

export const pageQuery = graphql`
  query ArticlesPage($id: ID!) {
    awsGraphQL {
      getHelpDetail(id: $id) {
        helpId
        helpCategoryId
        relatedHelpId
        title
        description
        body
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
