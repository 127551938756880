/**
 * 投票用共通フック
 */
import { API, graphqlOperation } from 'aws-amplify'
import { useEffect } from 'react'
import moment from 'moment'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { listHelpVotesId } from '../graphql/queries'
import { ListHelpVotesQuery } from '../API'
import {
  createHelpVote,
  incrementBadHelpVote,
  incrementGoodHelpVote,
} from '../graphql/mutations'

const awsmobile = require('../aws-exports')

type IExecVote = (helpId: string, isGood: boolean, success?: () => void) => void
type IReturn = {
  execVote: IExecVote
}

/**
 * 投票フック関数
 */
const useVote = (): IReturn => {
  useEffect(() => {
    API.configure(awsmobile.default)
  }, [])

  /** 投票実行 */
  const execVote: IExecVote = async (helpId, isGood, success) => {
    try {
      const month = moment().format('YYYY-MM')
      const result = (await API.graphql(
        graphqlOperation(listHelpVotesId, { helpId, month })
      )) as GraphQLResult<ListHelpVotesQuery>
      const items = result.data?.listHelpVotes?.items || []
      if (items.length === 0) {
        await API.graphql(
          graphqlOperation(createHelpVote, {
            input: {
              helpId,
              month,
              goodCount: isGood ? 1 : 0,
              badCount: isGood ? 0 : 1,
            },
          })
        )
      } else {
        const id = items[0]?.id || ''
        if (isGood) {
          await API.graphql(graphqlOperation(incrementGoodHelpVote, { id }))
        } else {
          await API.graphql(graphqlOperation(incrementBadHelpVote, { id }))
        }
      }
      success && success()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
  return { execVote }
}
export default useVote
