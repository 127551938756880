import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const H2 = styled.h2`
  width: 100%;
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;
  span {
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    position: relative;
    &:before,
    &:after {
      display: block;
      content: '';
      width: 22px;
      height: 14px;
      border-left: solid 8px rgba(128, 219, 191, 0.5);
      border-right: solid 8px rgba(128, 219, 191, 0.5);
      transform: skewX(306deg);
      position: absolute;
    }
    &:before {
      left: -40px;
    }
    &:after {
      right: -40px;
    }
  }
  ${BreakPoints.large} {
    &.nav-enable {
      width: calc(100vw - 8px);
      left: 0;
    }
  }
  ${BreakPoints.medium} {
    span {
      margin: 10px 0;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label: string
}

/**
 * 共通H2タグ
 * @param props
 * @constructor
 */
const CommonH2 = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <H2 className={className}>
      <span>{props.label}</span>
    </H2>
  )
}

export default CommonH2
