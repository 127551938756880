/* tslint:disable */
/* eslint-disable */

export const incrementGoodHelpVote = /* GraphQL */ `
  mutation IncrementGoodHelpVote($id: ID!) {
    incrementGoodHelpVote(id: $id) {
      helpId
      month
    }
  }
`
export const incrementBadHelpVote = /* GraphQL */ `
  mutation IncrementBadHelpVote($id: ID!) {
    incrementBadHelpVote(id: $id) {
      helpId
      month
    }
  }
`

export const createHelpVote = /* GraphQL */ `
  mutation CreateHelpVote(
    $input: CreateHelpVoteInput!
    $condition: ModelHelpVoteConditionInput
  ) {
    createHelpVote(input: $input, condition: $condition) {
      helpId
      month
    }
  }
`
