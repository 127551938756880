import styled from '@emotion/styled'
import React, { useState } from 'react'
import useVote from '../../hooks/useVote'
import BreakPoints from '../../styles/breakPoints'
import CommonBtn from '../atoms/commonBtn'
import ConfirmPopup, { ConfirmPopupType } from '../organisms/confirmPopup'

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 90px;
  margin: 0 60px 30px;
  font-size: 1.38em;
  span {
    display: block;
    margin: 0 20px 0 0;
  }
  ${BreakPoints.medium} {
    margin: 0 0 30px;
    font-size: 1em;
  }
  ${BreakPoints.smallest} {
    display: block;
    text-align: center;
    height: auto;
  }
`

type IProps = {
  /** ヘルプID */
  helpId: string
}
/**
 * 投票レイアウト
 * @constructor
 */
const ArticleVote = ({ helpId }: IProps) => {
  const { execVote } = useVote()
  const [isExec, setExec] = useState(false)
  const [popupType, setPopupType] = useState<ConfirmPopupType>('close')

  const onClick = (isGood: boolean) => () => {
    setExec(true)
    if (!isExec)
      execVote(helpId, isGood, () => {
        setPopupType(isGood ? 'good' : 'bad')
      })
  }

  return (
    <Div>
      <span>この情報は役に立ちましたか？</span>
      <CommonBtn
        size="medium"
        onClick={onClick(true)}
        level={isExec ? 'disabled' : 'weak'}
      >
        はい
      </CommonBtn>
      <CommonBtn
        size="medium"
        onClick={onClick(false)}
        level={isExec ? 'disabled' : 'weak'}
      >
        いいえ
      </CommonBtn>
      <ConfirmPopup type={popupType} onClose={() => setPopupType('close')} />
    </Div>
  )
}

export default ArticleVote
