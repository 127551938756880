/* tslint:disable */
/* eslint-disable */

export const listHelpVotesId = /* GraphQL */ `
  query ListHelpVotes($helpId: String!, $month: String!) {
    listHelpVotes(filter: { helpId: { eq: $helpId }, month: { eq: $month } }) {
      items {
        id
      }
    }
  }
`
