import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { IHelpDetail } from '../../hooks/useHelpData'
import BreakPoints from '../../styles/breakPoints'
import CommonH2 from '../atoms/commonH2'
import CommonH3 from '../atoms/commonH3'
import CustomMarkdown from '../atoms/customMarkdown'
import ArticleVote from './articleVote'
import useRouteParam from '../../hooks/useRouteParam'

const Section = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  article {
    width: 72%;
    max-width: 780px;

    .article-card {
      background: #ffffff;
      border-radius: 10px;
      padding: 25px 35px;
      max-width: 780px;
      margin: 0 0 30px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

      h2.head-h2 {
        margin: 0 0 15px -5px;
        border: none;
        display: block;
        position: relative;
        border-bottom: solid 3px #e5e5e5;
        line-height: 1.4em;

        &:before {
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 40px;
          height: 3px;
          content: '';
          background: #3ec7b3;
          transform: unset;
        }

        &:after {
          display: none;
        }

        span {
          margin: 0 0 3px;
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .related-help {
    border-top: solid 1px #cccccc;
    border-bottom: solid 1px #cccccc;
    padding: 20px 0 10px;

    h3 {
      font-size: 16px;
      margin: 0 0 15px;

      span {
        border-bottom: none;

        &:before {
          display: none;
        }
      }
    }

    li {
      margin: 0 0 10px 15px;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 7px;
        left: -12px;
        border-left: 8px solid #3ec7b3;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      a {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }

  aside {
    h3 {
      font-size: 16px;
      margin: 0 0 15px;
      padding-left: 20px;

      span {
        &:before {
          display: none;
        }
      }
    }

    width: 27%;
    max-width: 290px;
    min-width: 200px;
    position: sticky;
    top: 70px;
    margin: 0 0 -10px;

    ul {
      overflow: overlay;
      height: calc(100vh - 270px);
      width: initial;
      max-width: 290px;
      min-width: 200px;
      padding: 5px 0 0 20px;

      li {
        margin: 0 0 15px 15px;
        position: relative;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }

        a {
          color: #414141;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          padding-right: 5px;
          width: calc(100% - 20px);

          &:hover {
            color: #00b5a6;
          }
        }

        p {
          position: relative;
          width: 100%;
          background: #19ba9b;
          color: #ffffff;
          border-radius: 5px;
          left: -20px;
          padding: 5px 5px 5px 20px;
          margin: -5px 0;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 12px;
            left: 6px;
            border-left: 8px solid #fff;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    display: block;

    article,
    aside {
      margin: 0 auto;
      width: calc(100vw - 30px);
    }

    aside {
      border-top: solid 1px #dddddd;
      border-bottom: solid 1px #dddddd;
      padding-top: 30px;
      margin-bottom: 30px;
      max-width: unset;
      position: relative;
      top: unset;

      ul {
        overflow: unset;
        height: auto;
        width: 100%;
        max-width: unset;
        min-width: unset;
        padding: 0 20px;
      }
    }

    .related-help {
      margin-bottom: 30px;
    }
  }

  ${BreakPoints.medium} {
    article {
      width: calc(100vw - 20px);

      .article-card {
        padding: 15px 10px 15px 20px;
      }
    }
  }
`

type IProps = {
  /** ヘルプID */
  helpId: string
  /** タイトル */
  title: string
  /** 本文 */
  body: string
  /** 同一カテゴリの関連記事一覧 */
  categoryHelps: IHelpDetail[]
  /** ヘルプID指定の関連記事一覧 */
  relatedHelps: IHelpDetail[]
}
/**
 * ヘルプ詳細セクション
 * @constructor
 */
const ArticleSection = ({
  helpId,
  title,
  body,
  categoryHelps,
  relatedHelps,
}: IProps) => (
  <Section>
    <article>
      <div className="article-card">
        <CommonH2 label={title} className="head-h2" />
        <CustomMarkdown contents={body} />
      </div>
      {/* 関連IDが指定されている場合の関連記事一覧 */}
      {relatedHelps.length > 0 && (
        <div className="related-help">
          <CommonH3 label="関連する記事" />
          <ul>
            {relatedHelps.map((relatedHelp) => (
              <li key={`related-${relatedHelp.helpId}`}>
                <Link to={useRouteParam(`/help/article/${relatedHelp.helpId}`)}>
                  {relatedHelp.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <ArticleVote helpId={helpId} />
    </article>
    <aside>
      <h3>同じカテゴリの記事</h3>
      <ul>
        {categoryHelps.map((categoryHelp) => (
          <li key={`category-help-${categoryHelp.helpId}`}>
            {helpId === categoryHelp.helpId ? (
              <p>{categoryHelp.title}</p>
            ) : (
              <Link to={useRouteParam(`/help/article/${categoryHelp.helpId}`)}>
                {categoryHelp.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </aside>
  </Section>
)

export default ArticleSection
