import styled from '@emotion/styled'
import React from 'react'
import ReactMarkdown, { PluggableList } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

const Wrapper = styled.div`
  width: 100%;

  > p {
    margin: 0 0 15px;
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4em;
  }

  h1 {
    display: flex;
    width: calc(100% - 10px);
    height: auto;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    left: 10px;
    margin: 0 0 10px -5px;
    padding: 5px 6px 4px;
    border-radius: 0 5px 5px 0;
    background: #ccf8f1;
    font-size: 1.45em;

    &:before {
      display: block;
      content: '';
      position: absolute;
      border-radius: 5px 0 0 5px;
      width: 10px;
      left: -10px;
      height: 100%;
      margin: 0 0 1px;
      background: #a3f1e3;
    }
  }

  h2 {
    font-size: 1.28em;
    margin: 10px 5px 10px -5px;
    padding: 5px 6px 5px 10px;
    border-radius: 5px;
    background: rgba(220, 220, 220, 0.4);
  }

  .section-anchor {
    margin-top: -120px;
    padding-top: 120px;
  }

  h3 {
    font-size: 1.3em;
    margin: 20px 0 10px;
    padding: 0 4px 0 2px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
  }

  h4 {
    font-size: 1.18em;
    margin: 10px 0 -3px;
  }

  h5 {
    font-size: 1.1em;
    margin: 10px 0 -2px;
  }

  h6 {
    font-size: 0.83em;
    margin: 10px 0 -3px;
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin: 5px 0 10px;

    li {
      margin-bottom: 5px;
      text-indent: -16px;
      margin-left: 20px;

      p {
        display: inline;
      }
    }
  }

  ul {
    li {
      display: list-item;
      list-style: disc inside;

      ul {
        li {
          list-style: circle inside;
          margin-left: 30px;

          ul {
            li {
              list-style: square inside;
            }
          }
        }
      }
    }
  }

  ol {
    li {
      display: list-item;
      list-style: decimal inside;

      ol {
        li {
          margin-left: 30px;
        }
      }

      ul {
        li {
          list-style: disc inside;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    min-width: 60%;
    margin: 10px 0;

    th,
    td {
      border: 1px solid rgba(100, 100, 100, 0.6);
      padding: 4px;
    }

    th {
      background: rgba(220, 220, 220, 0.5);
    }
  }

  // Wiki指定クラス
  .text-ll {
    font-size: 1.5em;
  }

  .text-l {
    font-size: 1.2em;
  }

  .text-m {
    font-size: 1em;
  }

  .text-s {
    font-size: 0.85em;
  }

  .text-bold {
    font-weight: 600;
  }

  .text-gray {
    color: #ababab;
  }

  .text-strong {
    text-decoration: underline dashed 2.5px #96a4af;
  }

  .text-danger {
    background: linear-gradient(transparent 55%, #fdd5c9 55%);
  }

  .text-warning {
    background: linear-gradient(transparent 60%, rgb(255, 255, 102) 60%);
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .Q {
    width: 100%;
    padding: 3px 0 20px 40px;
    background-image: url(/images/official/top/imgFaqQ.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: top left;
    font-size: 1.38em;
    font-weight: 600;
    line-height: 1.3em;
    border-bottom: solid 1px #cccccc;
    margin: 0 0 20px;
  }

  .A {
    width: 100%;
    padding: 3px 0 20px 40px;
    background-image: url(/images/official/top/imgFaqA.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: top left;
  }
`
type IProps = {
  /** 内容 */
  contents: string
  /** サニタイズを行うかどうか */
  sanitize?: boolean
}

/**
 * カスタムマークダウン
 * @constructor
 */
const CustomMarkdown = ({ contents, sanitize }: IProps): JSX.Element => {
  const rehypePlugins: PluggableList = [rehypeRaw]
  if (sanitize) rehypePlugins.push(rehypeSanitize)
  let _contents = contents.replace(/<br \/> /gi, '\n')
  _contents = _contents.replace(/<br \/> /gi, '\n')
  _contents = _contents.replace(/<br \/>/gi, '\n')
  _contents = _contents.replace(/\n\n/gi, '<br />\n\n')
  _contents = _contents.replace(/\n/gi, '  \n')
  _contents = _contents.replace(/""/gi, '"')
  return (
    <Wrapper>
      <ReactMarkdown rehypePlugins={rehypePlugins}>{_contents}</ReactMarkdown>
    </Wrapper>
  )
}

export default CustomMarkdown
